<template>
  <div class="apply__container">
    <el-form
      ref="applicantForm"
      class="custom-form"
      :model="applicantForm"
      inline
    >
      <!-- 지원정보 -->
      <div>
        <h3 class="font-bold text-xl mt-5 mb-5">
          <span class="highlight required">
            <span>Position</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-form-item prop="jobId">
            <el-cascader
              v-model="selectedValue"
              :disabled="this.$store.getters.isSimpleApply"
              :options="cascaderList"
              show-all-levels
              filterable
              placeholder="Position"
              style="width: 400px"
              value=""
              separator=" > "
              @change="(value) => applicantForm.jobId = value[1]"
            />
          </el-form-item>
        </div>
      </div>

      <!-- 기본 인적사항 -->
      <div>
        <h3 class="font-bold text-xl mt-6 mb-5">
          <span class="highlight required">
            <span>Applicant Information</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-form-item prop="First Name">
            <el-input
              v-model="firstName"
              placeholder="First Name"
              name="namefield"
              class="filter-item"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item prop="Last Name">
            <el-input
              v-model="lastName"
              placeholder="Last Name"
              name="namefield"
              class="filter-item"
              style="width: 150px"
              @keydown.native="autoCompleteName"
            />
          </el-form-item>
          <el-form-item prop="country">
            <el-input
              v-model="applicantForm.country"
              placeholder="Country"
              type="text"
              class="filter-item"
              style="width: 220px"
              @keydown.native="autoCompleteName"
            />
          </el-form-item>
          <br>
          <el-form-item prop="email">
            <el-input
              v-model="applicantForm.email"
              placeholder="Email Address"
              type="email"
              class="filter-item"
              style="width: 250px"
            />
          </el-form-item>
          <el-form-item prop="phone">
            <el-input
              v-model="applicantForm.phone"
              placeholder="Phone Number"
              type="phone"
              class="filter-item"
              style="width: 250px"
            />
          </el-form-item>
        </div>
        <div style="margin-bottom: 15px">
          <el-checkbox v-model="applicantForm.visaCheck">
            I have a valid Korean working visa.
          </el-checkbox>
        </div>
        <div v-if="applicantForm.visaCheck">
          <div>
            <el-form-item prop="country">
              <el-input
                v-model="applicantForm.visaType"
                placeholder="Visa Type"
                type="text"
                class="filter-item"
                style="width: 250px"
              />
            </el-form-item>
            <el-form-item prop="country">
              <el-date-picker
                v-model="applicantForm.visaExpiryDate"
                type="date"
                placeholder="Expiry Date"
                default-value="2021-12-31"
              />
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>

    <!-- 첨부 자료 -->
    <div class="mt-12">
      <h3 class="font-bold text-xl mt-10 mb-5 flex items-center">
        Portfolio
      </h3>
      <div class="text-sm mb-7">
        <p class="mb-1 ml-3 font-primary-color">
          <i
            style="font-size: 18px; top: 7px"
            class="highlight__star mr-0.5"
          >*</i>
          Please upload your portfolio in Word/Excel/PPT or PDF format.
        </p>
        <p class="mb-1 ml-3 font-primary-color">
          <i
            style="font-size: 18px; top: 7px"
            class="highlight__star mr-0.5"
          >*</i>
          Please upload only the necessary files after decompressing the zip
          file except in unavoidable cases such as large-capacity original
          file.
        </p>
      </div>
      <div
        class="ml-1"
        style="width: 42%"
      >
        <el-upload
          action=""
          drag
          multiple
          :auto-upload="false"
          :file-list="uploadList"
          :on-remove="handleFileRemoveChange"
          :on-change="handleUploadChange"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">
            Upload files by dragging or clicking
          </div>
          <div class="text-sm font-primary-color">
            You can upload multiple files
          </div>
        </el-upload>
      </div>
      <div
        v-for="(url, urlIdx) in urlList"
        :key="`url${urlIdx}`"
        class="mb-1 urlList"
      >
        <el-input
          :key="`urlInput${urlIdx}`"
          v-model="urlList[urlIdx]"
          placeholder="http://"
          class="filter-item ml-1 mr-2"
          style="width: 360px"
        />
        <el-button
          v-if="!urlIdx"
          icon="el-icon-plus"
          type="primary"
          size="mini"
          circle
          @click="urlList.push('')"
        />
        <el-button
          v-else
          :key="`urlBtn${urlIdx}`"
          icon="el-icon-minus"
          type="danger"
          size="mini"
          circle
          @click="urlList.splice(urlIdx, 1)"
        />
      </div>
    </div>

    <!-- S: 버튼 -->
    <div class="applyNew__btnWrap">
      <el-button
        :disabled="dialogLoading"
        type="primary"
        @click="applySimple"
      >
        Apply
      </el-button>
    </div>
    <!-- E: 버튼 -->

    <!-- S: 로딩 모달 -->
    <div
      v-show="applyLoading"
      class="loading__modal"
    >
      <div class="tsmd__dim" />
      <div class="cookie__loading">
        <div class="cookie__loading--img">
          <img
            class="cookie"
            style="width: 400px"
            :src="`${loadingGif}?ts=${timeStamp}`"
            alt=""
          >
        </div>
      </div>
    </div>
    <!-- E: 로딩 모달 -->
  </div>
</template>
<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { getJobs, getRoute, getUploadUrl } from '@/api/job';
import { registerSimple, removeFile } from '@/api/applicant';
import '@/plugins/element.js';

export default {
  name: 'ApplySimpleEN',
  data() {
    return {
      applicationId: 0,
      applyLoading: false,
      loading: false,
      formData: this.applicantForm,
      dialogLoading: false,
      appliableList: [],
      cascaderList: [],
      firstName: '',
      lastName: '',
      applicantForm: {
        name: '',
        phone: '',
        email: '',
        country: '',
        visaCheck: false,
        visaType: '',
        visaExpiryDate: '',
        jobId: '',
        applyRouteId: 9,
      },
      selectedValue: [],
      uploadList: [],
      urlList: [''],
      applyResult: {},
      showFinalCheck: false,
    };
  },
  computed: {
    timeStamp(){
      return new Date().getTime();
    },
    loadingGif() {
      return require('@/assets/recruit/recruit_loading.gif');
    },
  },
  watch:{
    selectedValue() {
      if(typeof this.selectedValue[0] === 'number'){
        this.$store.dispatch('app/setRecruitType',this.selectedValue[0]);
        this.$store.dispatch('user/setJobId',this.selectedValue[1]);
      }
    },
  },
  mounted() {
    if(JSON.parse(localStorage.getItem('applicantForm'))!==null) {
      this.setLocalStorage();
    }
  },
  async created() {
    await this.getJobList();
    if (this.$store.getters.jobId) {
      this.applicantForm.jobId = parseInt(this.$store.getters.jobId, 10);
      const job = this.appliableList.find(j => j.id === this.applicantForm.jobId);
      this.selectedValue = [job.jobTypeId, job.id];
    }
  },
  methods: {
    async getJobList() {
      const query = {};
      const result = await getJobs(query);
      const list = [];
      const cascaderList = [{
        value: 1,
        label: 'Game Design',
        children: []
      }, {
        value: 2,
        label: 'Data Analysis',
        children: []
      }, {
        value: 3,
        label: 'Marketing',
        children: []
      }, {
        value: 4,
        label: 'Art',
        children: []
      }, {
        value: 6,
        label: 'Programming',
        children: []
      }, {
        value: 7,
        label: 'Researcher',
        children: []
      },{
        value: 12,
        label: 'People & Culture',
        children: []
      }, {
        value: 13,
        label: 'Video & Media',
        children: []
      }, {
        value: 14,
        label: 'Freelancer',
        children: []
      }, {
        value: 15,
        label: 'Afiliate',
        children: []
      }, {
        value: 16,
        label: 'Intern',
        children: []
      }];

      result.data.items.forEach(type => {
        type.job.forEach(j => {
          const jobObj = {
            type: type.name,
            type_en: type.name_en,
            ...j
          };

          list.push(jobObj);

          const parent = cascaderList.find(c => c.value === j.jobTypeId);
          if (parent) {
            parent.children.push({
              value: j.id,
              label: j.alias || j.name
            });
          }
        });
      });
      this.appliableList = list;
      this.cascaderList = cascaderList;
    },
    autoCompleteName() {
      this.applicantForm.name = this.firstName + ' ' + this.lastName;
    },
    validateEmail() {
      let isEmailValid = false;
      const email = this.applicantForm.email;
      const regex = /\S+@\S+\.\S+/;
      if(!regex.test(email)){
        this.$message({
          message: 'Please re-enter your e-mail.',
          type: 'error'
        });
        return false;
      }else{
        isEmailValid = true;
      }
      return isEmailValid;
    },
    validateApplicantForm() {
      if(!this.applicantForm.jobId){
        this.$message({
          message: 'Please select your job.',
          type: 'error'
        });
        return false;
      }else if(!this.firstName){
        this.$message({
          message: 'Please enter your first name.',
          type: 'error'
        });
        return false;
      }else if(!this.lastName){
        this.$message({
          message: 'Please enter your last name.',
          type: 'error'
        });
        return false;
      }else if(!this.applicantForm.email) {
        this.$message({
          message: 'Please enter your e-mail.',
          type: 'error'
        });
        return false;
      }else if(!this.validateEmail()) {
        return false;
      }else if(!this.applicantForm.phone) {
        this.$message({
          message: 'Please enter your phone.',
          type: 'error'
        });
        return false;
      }
      return true;
    },
    async handleFileRemoveChange(file, fileList) {
      if (file.id) {
        await removeFile({ applicationId: this.applicationId, id: file.id });
      }

      this.uploadList = fileList;
    },
    handleUploadChange(file, fileList) {
      this.uploadList = fileList;
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
    },
    async uploadFiles(data) {
      let fileList = [];

      if (data.length) {
        const sParam = data.map(file => {
          return {
            name: file.name,
            type: file.raw.type
          };
        });

        const signedResult = await getUploadUrl({ items: sParam });
        const uploadUrlList = signedResult.data.uploadUrlList;
        const downloadUrlList = signedResult.data.downloadUrlList;

        const promiseList = uploadUrlList.map((url, idx) => {
          return new Promise((resolve) => {
            axios.put(url, data[idx].raw, {
              headers: {
                'Content-Type': data[idx].raw.type
              }
            }).then(() => {
              resolve(true);
            }).catch(() => {
              resolve(false);
            });
          });
        });

        const result = await Promise.all(promiseList);

        if (result.indexOf(false) > -1) {
          this.$alert(this.$t('recruit.apply_upload_fail'), this.$t('recruit.apply_fail_title'), { dangerouslyUseHTMLString: true });
          this.dialogLoading = false;

          return false;
        }

        downloadUrlList.forEach((url, idx) => {
          sParam[idx].url = url;
        });

        fileList = downloadUrlList.map((url, idx) => {
          return {
            ...sParam[idx],
            url
          };
        });
      }

      return fileList;
    },
    async applySimple() {
      if(!this.validateApplicantForm()){
        return false;
      }

      if (window.navigator.userAgent.indexOf('Trident') > -1) {
        this.applicantForm.name = document.getElementsByName('namefield')[0].value;
      }

      const referrer = Cookies.get('referrer') || '';

      let applyResult;

      const fileList = await this.uploadFiles(this.uploadList);
      const urlListObj = this.urlList.map(item => item = { url: item });

      applyResult = await registerSimple({
        applicantForm: this.applicantForm,
        urlList: urlListObj,
        referrer,
        fileList,
      });

      if (applyResult.data.success) {
        this.applyLoading = true;
        setTimeout(() => {
          this.$router.push('/recruit/apply/complete');
        }, 2500);
      } else {
        this.$alert(this.$t('recruit.apply_fail'), this.$t('recruit.apply_fail_title'), { dangerouslyUseHTMLString: true });
      }
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/apply.scss";
</style>
<style lang="scss">
.mobile .apply__new {
  .el-upload {
    display: block;
    .el-upload-dragger {
      width: 100%;
    }
  }
  .el-select {
    width: 100% !important;
  }
  .urlList {
    display: block;
    .el-input {
      margin-top: 1rem;
      width: calc(100% - 40px) !important;
    }
  }
}
.isFresh__checkbox {
  .el-checkbox__label {
    font-size: 16px;
    position: relative;
    top: 2px;
  }
  .el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  .el-checkbox__inner::after {
    width: 5px;
    height: 9px;
    left: 5px;
  }
}
.applyNew__preview {
  .el-dialog__close {
    font-size: 24px;
  }
  .mobile & .el-dialog {
    width: 100% !important;
    margin-top: 0 !important;
  }
}
.form__item--block {
  display: block !important;
  .el-form-item__content {
    display: block !important;
  }
}
.education--form {
  .el-form-item {
    margin-bottom: 0 !important;
  }
  .el-form-item__error {
    padding-top: 0px !important;
  }
}
.complete__page {
  .complete_right-arrow {
    display: inline-block;
    width: 0px;
    height: auto;
    position: relative;
    bottom: 2px;
    transition: width 0.3s;
  }
  .tsmd__save__btn:hover .complete_right-arrow {
    width: 19px !important;
    margin-left: 10px;
  }
}
.el-rate__icon {
  font-size: 24px;
}
.bar__animation {
  width: 0%;
  height: 60%;
  top: 15px;
  left: 0;
  background: rgba($color: #f3791f, $alpha: 0.5);
  z-index: 0;
  animation: barAnimation 0.4s ease-in-out 1 forwards;
  &__2 {
    height: 60%;
    background: rgba($color: #ffdf2c, $alpha: 0.5);
    transition-delay: 0.5s;
    animation-delay: 0.4s;
  }
}
@keyframes barAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>
